.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 22px 12px 16px;
  border-bottom: 1px solid black;
  background-color: #1C1D1E;
}

.h1 {
  font-size: 24px;
}

.accent {
  color: #F98B4D;
}

.loginBtn {
  font-size: 14px;
  background-color: #363638;
  width: 110px;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border: 1px solid #454546;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.loginBtn:active {
  transform: scale(0.9);
}

@media (max-width: 700px) {
  .header {
    padding-left: 82px;
    padding-right: 16px;
  }

  .header.logout {
    padding-left: 22px;
  }

  .loginBtn {
    width: 90px;
  }
}
