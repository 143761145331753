.formItem {
  margin-bottom: 24px;
}

.label {
  display: block;
  font-size: 16px;
  margin-bottom: 12px;
}

.input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #1C1D1E;
  border: 1px solid #000;
}

.input:focus {
  outline: none;
  background-color: #242525;
}

.input:focus::placeholder {
  opacity: 0;
}

.channelBtn {
  display: block;
  font-size: 14px;
  text-align: center;
  background-color: #363638;
  padding: 10px 36px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border: 1px solid #454546;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.channelBtn:active {
  transform: scale(0.9);
}
