.emailFormWrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.form {
  background-color: #1C1D1E;
  padding: 24px;
  border: 1px solid black;
  border-radius: 8px;
}

.input {
  width: 340px;
  height: 34px;
  background-color: #121314;
  border: 1px solid black;
  border-radius: 5px;
  padding-left: 14px;
  margin-right: 16px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
}

.input::placeholder {
  user-select: none;
}

.input:focus {
  border: 1px solid black;
  outline: 1px solid black;
}

.input:focus::placeholder {
  color: transparent;
}

.button {
  font-size: 14px;
  background-color: #363638;
  width: 110px;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border: 1px solid #454546;
  cursor: pointer;
  transition: transform 0.2s ease;
  user-select: none;
}

.button:active {
  transform: scale(0.9);
}

@media (max-width: 700px) {
  .form {
    width: 80%;
  }

  .input {
    width: 100%;
    margin-bottom: 16px;
    text-overflow: ellipsis;
  }

  .button {
    width: 100%;
  }
}
