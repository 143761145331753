.sidebarWrap {
 padding: 16px;
}

.newChannelBtn {
  display: block;
  font-size: 14px;
  text-align: center;
  background-color: #363638;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border: 1px solid #535355;
  transition: transform 0.2s ease;
}

.newChannelBtn:active {
  transform: scale(0.9);
}

.channelsHeading {
  font-size: 10px;
  text-align: right;
  margin-top: 38px;
  margin-bottom: 8px;
  text-transform: uppercase;
  opacity: 0.5;
}

.channel {
  display: block;
  background-color: #363638;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 12px;
}

.active {
  background-color: #4F505B;
}

.channelTitle {
  display: block;
  font-size: 16px;
  margin-bottom: 16px;
}

.channelDescr {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.7;
}

.openChannelsBtn {
  width: 40px;
  height: 40px;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #363638;
  cursor: pointer;
}

.newChannelIcon {
  display: none;
}

@media (max-width: 700px) {
  .openChannelsBtn {
    display: flex;
  }

  .newChannelIcon { 
    display: block;
    line-height: 0;
  }

  .open.openChannelsBtn,
  .open.newChannelIcon {
    display: none;
  }

  .newChannelBtn:not(.open) {
    width: 40px;
    height: 40px;
    border: unset;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .channelList,
  .channelsHeading,
  .newChannelBtnText {
    display: none;
  }

  .open.channelList,
  .open.channelsHeading,
  .open.newChannelBtnText {
    display: block;
  }

  .open.newChannelIcon {
    line-height: 1.2;
  }
}
