.listItem {
  list-style: none;
  margin: 0;
  display: grid;
  grid-template-columns: auto 1fr 80px;
  column-gap: 10px;
  padding: 22px;
}

.messageWrap {
  max-width: 800px;
}

.message {
  opacity: 0.8;
  margin-bottom: 8px;
  margin-top: 4px;
}

.date,
.time {
  font-size: 12px;
  color: #717171;
}

.date {
  text-align: right;
}

.time {
  margin-top: 4px;
}

.gravatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.pickerWrap {
  position: relative;
  display: inline-block;
}

.reaction,
.picker,
.reactionBtn {
  font-size: 14px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 26px;
  border-radius: 4px;
  background-color: #1C1D1E;
  margin-right: 4px;
  margin-bottom: 4px;
}

.picker,
.reactionBtn {
  border: none;
  user-select: none;
  cursor: pointer;
}

.reactionBtn {
  background-color: #363638;
  margin-bottom: 0;
  margin-right: 0;
}

.reactionBtn:hover {
  transform: scale(1.3);
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.35);
}

.picker:hover {
  background-color: #363638;
}

.reactionBtnWrap {
  position: absolute;
  width: 60vw;
  max-width: 734px;
  flex-wrap: wrap;
  top: -16px;
  left: 24px;
  row-gap: 4px;
  display: flex;
  gap: 4px;
  background-color: #1C1D1E;
  padding: 8px;
  border: 1px solid #000;
  border-radius: 4px;
  transform: translateY(-100%);
  z-index: 999;
}

@media (max-width: 700px) {
  .listItem {
    padding: 16px;
    grid-template-columns: auto 1fr 65px;
  }

  .gravatar {
    width: 28px;
    height: 28px;
  }

  .date,
  .time {
    font-size: 10px;
  }

  .reaction,
  .picker,
  .reactionBtn {
    width: 28px;
    height: 23px;
    line-height: 0;
  }
}
